<template>
  <div class="ml-4 pt-2">
    <div class="hidden-sm-and-down">
      <v-row v-if="isLoggedIn" class="refer-code-link-row">
        <v-col cols="10" class="pl-0">
          <v-row no-gutters>
            <v-col cols="6">
              <span class="black--text font-weight-bold">{{ $t(`label.mobileReferalCode`) }}</span>
              <v-text-field class="tier-info border-referral" rounded dense hide-details="auto" v-model="referCode" readonly>
                <template v-slot:append>
                  <v-btn icon @click="copyRefCode" color="primary" dark><img width="24" height="24" src="/static/svg/profileReferral/copy.svg" alt="" /></v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <span class="black--text font-weight-bold">{{ $t(`label.copyReferralLink`) }}</span>
              <v-text-field class="tier-info border-referral" rounded color="#0094FF" dense hide-details="auto" v-model="referLink" readonly>
                <template v-slot:append>
                  <v-btn icon @click="copyReferralLink" color="primary" dark><img width="24" height="24" src="/static/svg/profileReferral/copy.svg" alt="" /></v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="refer-timeline">
        <v-col cols="12" class="pa-0">
          <span class="referral-title">{{ $t(`label.newReferralTitle`) }}</span>
        </v-col>
        <v-col cols="12" class="pa-0">
          <span class="pt-3" style="color: #0094ff !important; font-weight: bold">{{ $t(`label.newReferralDes`) }}</span>
        </v-col>
        <v-col class="timeline-container" cols="9">
          <v-timeline dense style="padding-top: 10px;">
            <v-timeline-item fill-dot color="#333333" class="active-tier">
              <template v-slot:icon>
                <img style="width:15px;" :src="`/static/svg/tier-icon.svg`" />
              </template>

              <v-row style="align-items: center">
                <v-col :cols="isLoggedIn ? '6' : '6'">
                  <v-card class="tier-card text-center active-tier">
                    <v-card-text class="font-weight-bold">{{ isLoggedIn ? memberInfo.memberCode : $t(`label.you`) }}</v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="2" v-if="isLoggedIn" style="padding: unset">
                  <v-card class="tier-card text-center active-tier">
                    <v-card-text class="font-weight-bold">{{ $t(`label.total`) }}</v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item v-for="(item, i) in tierList" :key="i" fill-dot color="#D9D9D9">
              <template v-slot:icon>
                <img style="width:15px;" :src="`/static/svg/tier-icon-black.svg`" />
              </template>
              <v-row style="align-items: center">
                <v-col :cols="isLoggedIn ? '6' : '6'">
                  <v-card class="tier-card text-center" color="grey lighten-2">
                    <v-card-text class="font-weight-bold black--text">{{ item.title }} ({{ item.percent }}%)</v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="2" v-if="isLoggedIn" style="padding: unset">
                  <v-card>
                    <v-card-text class="tier-card text-center font-weight-bold black--text">{{ item.downlineCount }}</v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <div v-if="isLoggedIn">
        <v-row>
          <v-col cols="4">
            <v-btn class="invite-frd-btn" @click="share">
              {{ $t(`label.inviteFriends`) }}
              <img class="share-img" src="/static/svg/share-outline.svg" />
            </v-btn>
          </v-col>
        </v-row>
        <p class="ref-bonus-title mt-4 black--text">{{ $t(`label.referralBonus`) }}</p>
        <v-row>
          <v-col cols="5">
            <div>
              <span class="black--text">{{ $t(`label.referralFreeBonus`) }}</span>
              <!--          <v-text-field class="tier-info small-rounded" rounded dense hide-details="auto" readonly></v-text-field>-->
              <v-card elevation="0" class="font-weight-bold justify-start d-flex" color="tier-info small-rounded text-center pa-1 ml-0 mx-auto input-card">
                <span class="pl-2">{{ decimalTruncate(downlineDetail.bonus_amount, 2) }}</span>
              </v-card>
            </div>
            <div class="my-2">
              <span class="black--text">{{ $t(`label.depositRequired`) }}</span>
              <v-card elevation="0" class="font-weight-bold justify-start d-flex" color="tier-info small-rounded text-center pa-1 mx-auto ml-0 input-card">
                <span class="pl-2">{{ decimalTruncate(downlineDetail.current_deposit, 2) }} / {{ decimalTruncate(downlineDetail.required_deposit, 2) }}</span>
              </v-card>
            </div>
            <div>
              <span class="black--text">{{ $t(`label.turnoverRequired`) }}</span>
              <v-card elevation="0" class="font-weight-bold justify-start d-flex" color="tier-info small-rounded text-center pa-1 mx-auto ml-0 input-card">
                <span class="pl-2">{{ decimalTruncate(downlineDetail.current_valid_bet, 2) }} / {{ decimalTruncate(downlineDetail.required_valid_bet, 2) }}</span>
              </v-card>
              <!--          <v-text-field class="tier-info small-rounded" rounded dense hide-details="auto" readonly></v-text-field>-->
            </div>
          </v-col>
          <v-col cols="5">
            <p class="black--text mb-0">{{ $t(`label.youReferralStatus`) }}</p>
            <v-card elevation="0" class="commission-content-card" style="min-width: 100%;" color="commission-content-holder base pa-0">
              <v-card-text class="pa-0">
                <v-row no-gutters>
                  <v-col cols="12" class="text-center d-flex pa-2">
                    <v-col cols="6" class="pa-0" style="border-right: 2px dotted rgba(8,8,8);">
                      <v-row no-gutters class=" d-flex justify-center">
                        <div>
                          <label>{{ $t(`label.friendAccepted`) }}</label>
                        </div>
                      </v-row>
                      <v-row no-gutters class="d-flex justify-center">
                        <div class="text-center py-10">
                          <label class="referral-text">{{ uplineReferral.accepted_referral }}</label>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0">
                      <v-row no-gutters class="d-flex justify-center">
                        <div>
                          <label>{{ $t(`label.friendCompleted`) }}</label>
                        </div>
                      </v-row>
                      <v-row no-gutters class="d-flex justify-center">
                        <div class="text-center py-10">
                          <label class="referral-text" style="color: #0094FF;">{{ uplineReferral.current_referral }}</label>
                        </div>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
                <!--                <v-row no-gutters class="d-flex justify-center align-center pb-2">-->
                <!--                  <v-btn style="width: 200px; color: #fff;" disabled>{{ $t(`button.apply`) }}</v-btn>-->
                <!--                </v-row>-->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <span class="pt-2" style="color: #0094ff !important;">{{ $t(`label.newReferralInvitation`) }}</span>
        </v-row>
      </div>
    </div>

    <!-- mobile view    -->
    <v-row class="hidden-md-and-up">
      <v-col cols="12">
        <p>{{ $t('info.mobile_referal_tier') }}</p>
        <v-row v-if="!isLoggedIn">
          <v-col cols="12">
            <span>{{ $t('info.mobile_referal_beforeLogin') }}</span>
          </v-col>
          <v-col class="inpage-before-login-first text-center beforeLoginBtn" @click="goToRegisterPage()">
            <b>{{ $t(`label.register`) }}</b>
          </v-col>
          <v-col class="inpage-navigator-before-login-second text-center beforeLoginBtn" @click="goToLoginPage()">
            <b>{{ $t(`label.login`) }}</b>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="text-left">
            <span>{{ $t(`label.mobileReferalCode`) }}</span>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col md="6" sm="8">
                <v-card class="referralCode-card text-center">
                  <v-card-text>{{ referCode }}</v-card-text>
                  <span></span>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="fill-height share-card" @click="share()">
                  <!--              <v-btn height="100%" width="100%" class="sharenow-btn">Share now</v-btn>-->
                  <span style="font-size: 1.3rem;">SHARE</span>
                  <div class="game-menu-image" :style="`--src: url('/static/svg/share.svg')`">
                    <img :url="`/static/svg/share.svg`" class="side-menu-icon" />
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!--      <v-col>-->
      <!--        <v-row> Your Referral Code:</v-row>-->
      <!--        <v-row></v-row>-->
      <!--      </v-col>-->
    </v-row>
    <v-row class="hidden-md-and-up">
      <v-col class="timeline-container col">
        <v-timeline dense>
          <v-timeline-item fill-dot color="grey lighten-2" class="active-tier">
            <template v-slot:icon>
              <img style="width:22px;" :src="`/static/svg/tier-icon.svg`" />
            </template>

            <v-row style="align-items: center">
              <v-col :cols="isLoggedIn ? '8' : '12'">
                <v-card class="tier-card text-center active-tier">
                  <v-card-text>{{ isLoggedIn ? memberInfo.memberCode : `You` }}</v-card-text>
                </v-card>
              </v-col>
              <v-col v-if="isLoggedIn" style="padding: unset">
                <v-card class="tier-card text-center active-tier">
                  <v-card-text>{{ totalDownlineCount }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item v-for="(item, i) in tierList" :key="i" fill-dot color="grey lighten-2">
            <template v-slot:icon>
              <img style="width:22px;" :src="`/static/svg/tier-icon.svg`" />
            </template>
            <v-row style="align-items: center">
              <v-col :cols="isLoggedIn ? '8' : '12'">
                <v-card class="tier-card text-center" color="grey lighten-2">
                  <v-card-text>{{ item.title }} ({{ item.percent }}%)</v-card-text>
                </v-card>
              </v-col>
              <v-col v-if="isLoggedIn" style="padding: unset">
                <v-card>
                  <v-card-text class="tier-card text-center">{{ item.downlineCount }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { locale, uiHelper } from '@/util'
import { REFERRAL_GET_DOWNLINES, REFERRAL_GET_OVERVIEW } from '@/store/referral.module'
import { AFFILIATE_DOWNLINE_DETAIL, AFFILIATE_UPLINE_REFERRAL } from '@/store/affiliate.bonus.module'

export default {
  name: 'referralTier',
  data: () => ({
    routeName: ROUTE_NAME,
    referCode: '',
    referLink: '',
    tierList: [
      {
        title: locale.getMessage(`label.tier1`),
        percent: '1',
        currentTier: true,
        downlineCount: 0
      },
      {
        title: locale.getMessage(`label.tier2`),
        percent: '0.7',
        downlineCount: 0
      },
      {
        title: locale.getMessage(`label.tier3`),
        percent: '0.3',
        downlineCount: 0
      }
    ],
    overview: {},
    downlineBonusAmount: 0,
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  created() {
    if (!_.isEmpty(this.$store.state.referral.overview)) {
      this.referCode = this.$store.state.referral.overview.my_referral_code
      this.referLink = uiHelper.getHostname() + '?refer_code=' + this.referCode
    } else {
      this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
      this.$store.dispatch(`${REFERRAL_GET_DOWNLINES}`)
    }

    if (_.isEmpty(this.$store.state.affiliateBonus.affiliateDownlineDetail)) {
      this.$store.dispatch(`${AFFILIATE_DOWNLINE_DETAIL}`)
    }

    if (_.isEmpty(this.$store.state.affiliateBonus.affiliateUplineReferral)) {
      this.$store.dispatch(`${AFFILIATE_UPLINE_REFERRAL}`)
    }
  },
  computed: {
    memberInfo() {
      return this.$store.state.member.info
    },
    totalDownlineCount() {
      let total = 0

      this.tierList.forEach(_tier => {
        total += _tier.downlineCount
      })

      return total
    },
    dateRangeText() {
      let date1 = this.dates[0] ? this.dates[0] : ''
      let date2 = this.dates[1] ? this.dates[1] : ''
      return date1 + ' - ' + date2
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },
    referralConfig() {
      return this.$store.state.referral.config
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    webShareApiSupported() {
      return navigator.share
    },
    downlineDetail() {
      return this.$store.state.affiliateBonus.affiliateDownlineDetail
    },
    uplineReferral() {
      return this.$store.state.affiliateBonus.affiliateUplineReferral
    }
  },
  watch: {
    referralOverview() {
      this.overview = this.$store.state.referral.overview

      this.tierList[0].downlineCount = this.overview.downline_lv1
      this.tierList[1].downlineCount = this.overview.downline_lv2
      this.tierList[2].downlineCount = this.overview.downline_lv3

      this.tierList[0].percent = this.getConfig(1)
      this.tierList[1].percent = this.getConfig(2)
      this.tierList[2].percent = this.getConfig(3)

      if (!_.isEmpty(this.$store.state.referral.overview)) {
        this.referCode = this.$store.state.referral.overview.my_referral_code
        this.referLink = uiHelper.getHostname() + '?refer_code=' + this.referCode
      }
    }
  },
  methods: {
    decimalTruncate(num, fixed) {
      if (num == null) {
        num = 0
      }
      let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?')
      return parseFloat(num.toString().match(re)[0])
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getConfig(index) {
      let config = this.referralConfig.find(c => c.level == index)
      if (config) {
        return config.percentage
      } else {
        return 0
      }
    },
    copyRefCode() {
      let $f = this
      if (this.referCode !== '') {
        this.$copyText(this.referCode).then(function(e) {
          console.log('Copied! e:', e)
          $f.alertMessage = 'Referral Code Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    },
    copyReferralLink() {
      let $f = this
      if (this.referLink !== '') {
        this.$copyText(this.referLink).then(function(e) {
          console.log('Copied! e:', e)
          $f.alertMessage = 'Referral Link Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    },
    share() {
      const sharing = {
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.referralOverview.my_referral_code + '.Refer a friend now and earn commission now! ',
        url: this.referLink
      }
      if (this.webShareApiSupported) {
        navigator.share(sharing)
      } else {
        let $f = this
        this.$copyText(this.share_url).then(function(e) {
          $f.alertMessage = 'Referral Link Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    }
  }
}
</script>

<style lang="scss">
.border-referral {
  border-radius: 10px;
}
.referral-title {
  font-weight: bolder;
  font-family: 'Roboto', sans-serif;
  font-size: 1.25rem;
  color: #000000;
}

.commission-content-card {
  //min-width: 252px;
  //max-width: 332px;
  width: 100%;
  //height: 250px;

  @media (max-width: 600px) {
    max-width: 100%;
  }

  .referral-text {
    font-size: 50px;
    font-weight: 700;
  }
}

.ref-bonus-title {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1rem;
}
.invite-frd-btn {
  width: 100%;
  text-transform: capitalize;
  background-color: #0094ff !important;
  border-radius: 10px;
  color: #ffffff !important;
  .share-img {
    margin-left: 8px;
    width: 20px;
    height: 20px;
  }
}
.tier-info {
  width: 90%;
  color: #0094ff !important;
  background-color: #f3f5f6 !important;
  .v-input__slot {
    background-color: #f3f5f6 !important;
    //padding: 8px 12px 8px 15px !important;
  }
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: #0094ff !important;
  }

  input {
    color: #0094ff !important;
  }

  &.small-rounded {
    height: 35px;
    border-radius: 10px !important;
    &.v-text-field--rounded {
      border-radius: 10px !important;
    }

    .v-input__slot {
      height: 35px !important;
    }
  }
}

.timeline-container {
  margin-right: 20px;
}

.v-timeline:before {
  bottom: 32px !important;
  height: 75% !important;
  top: 33px !important;
}
.tier-card.active-tier.v-card {
  background-color: #333333;
  color: var(--v-newMobilePrimary-base) !important;
  .v-card__subtitle,
  .v-card__text {
    color: var(--v-newMobilePrimary-base) !important;
  }
}
.active-tier {
  .v-timeline-item__inner-dot {
    background-color: #000000 !important;
    color: var(--v-newMobilePrimary-base) !important;
  }
}

.active-tier {
  .v-timeline-item__inner-dot {
    background-color: #000000 !important;
    color: var(--v-newMobilePrimary-base) !important;
  }
}

.tier-card {
  background-color: #d9d9d9;
  color: #000000;
}

@media screen and (min-width: 990px) {
  .v-timeline-item {
    position: relative;
  }
  .v-timeline-item:before {
    content: '';
    position: absolute;
    top: 35%;
    left: 8%;
    width: 55%;
    height: 2px;
    background-color: #d9d9d9;
    transform: translateY(-50%);
  }
  .v-timeline-item.active-tier:before {
    content: '';
    position: absolute;
    top: 35%;
    left: 8%;
    width: 55%;
    height: 2px;
    background-color: #ffce01;
    transform: translateY(-50%);
  }
}
</style>
